import Document from '@/model/document';
import PageOptions from '@/model/page/page-options';
import PageableRequirement from '@/model/page/pageable-requirement';
import RequirementDocument from '@/model/requirement-document';
import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import DocumentExpiryRequest from '@/model/request/document-expiry-request';
import { findModifiedRequirementDocument } from '@/helper/methods';
import DocumentContainer from '@/model/document-container';
import Person from '@/model/person';
import Business from '@/model/business';
import Tool from '@/model/tool';
import Pageable from '@/model/page/pageable';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';

export default {
  namespaced: true,
  state: {
    requirements: new Array<PageableRequirement<RequirementDocument>>(),
    documents: new Array<Document>(),
    operationalUnitDocuments: {} as Pageable<Document>,
    placeDocuments: {} as Pageable<Document>,
    personEducationDocuments: {} as Pageable<Document>,
    personDocuments: {} as Pageable<Document>,
    toolDocuments: {} as Pageable<Document>,
    layouts: new Array<Document>(),
  },
  mutations: {
    setRequirements(
      state: { requirements: Array<PageableRequirement<RequirementDocument>> },
      payload: Array<PageableRequirement<RequirementDocument>>,
    ) {
      state.requirements = payload;
    },
    setCategoryRequirements(state, payload: PageableRequirement<RequirementDocument>) {
      for (const requirementList of state.requirements) {
        if (requirementList.category === payload.category) {
          requirementList.items = requirementList.items.concat(payload.items);
          requirementList.currentPage = payload.currentPage;
          break;
        }
      }
    },
    setDocuments(state: { documents: Document[] }, payload: Document[]) {
      state.documents = payload;
    },
    setOperationalUnitDocuments(state: { operationalUnitDocuments: Pageable<Document> }, payload: Pageable<Document>) {
      if (payload.currentPage === 0) {
        state.operationalUnitDocuments = payload;
      } else {
        state.operationalUnitDocuments.items = state.operationalUnitDocuments.items.concat(payload.items);
        state.operationalUnitDocuments.currentPage = payload.currentPage;
      }
    },
    setPlaceDocuments(state: { placeDocuments: Pageable<Document> }, payload: Pageable<Document>) {
      if (payload.currentPage === 0) {
        state.placeDocuments = payload;
      } else {
        state.placeDocuments.items = state.placeDocuments.items.concat(payload.items);
        state.placeDocuments.currentPage = payload.currentPage;
      }
    },
    setPersonDocuments(state: { personDocuments: Pageable<Document> }, payload: Pageable<Document>) {
      if (payload.currentPage === 0) {
        state.personDocuments = payload;
      } else {
        state.personDocuments.items = state.personDocuments.items.concat(payload.items);
        state.personDocuments.currentPage = payload.currentPage;
      }
    },
    setToolDocuments(state: { toolDocuments: Pageable<Document> }, payload: Pageable<Document>) {
      if (payload.currentPage === 0) {
        state.toolDocuments = payload;
      } else {
        state.toolDocuments.items = state.toolDocuments.items.concat(payload.items);
        state.toolDocuments.currentPage = payload.currentPage;
      }
    },
    addDocuments(state: { documents: Document[] }, payload: Document[]) {
      state.documents = state.documents?.concat(payload) ?? [payload];
    },
    modifyDocument(state: { documents: Document[] }, payload: Document) {
      const index = state.documents.findIndex((document: Document) => document.id === payload.id);
      if (index >= 0) {
        Object.assign(state.documents[index], payload);
      }
    },
    removeDocument(state: { documents: Document[] }, payload: number) {
      state.documents = state.documents.filter((document: Document) => document.id !== payload);
    },
    setContainer(state: { requirements: Array<PageableRequirement<RequirementDocument>> }, payload: DocumentContainer) {
      const modifiedRequirement = findModifiedRequirementDocument(state.requirements, payload);
      const modifiedContainer = (modifiedRequirement?.documentContainers ?? [])
        .concat(modifiedRequirement?.inheritedContainer)
        ?.find((item: DocumentContainer) => item.id === payload.id);
      Object.assign(modifiedContainer as any, payload);
    },
    setContainerPeople(
      state: { requirements: Array<PageableRequirement<RequirementDocument>> },
      { container, person }: { container: DocumentContainer; person: Person },
    ) {
      const modifiedRequirement = findModifiedRequirementDocument(state.requirements, container);
      const modifiedContainer = (modifiedRequirement?.documentContainers ?? [])
        .concat(modifiedRequirement?.inheritedContainer)
        ?.find((item: DocumentContainer) => item.id === container.id);
      if (modifiedContainer) {
        const index = modifiedContainer?.people.findIndex((item: Person) => item.id === person.id);
        index >= 0 ? modifiedContainer.people.splice(index, 1) : modifiedContainer.people.push(person);
      }
    },
    setContainerPlaces(
      state: { requirements: Array<PageableRequirement<RequirementDocument>> },
      { container, place }: { container: DocumentContainer; place: Business },
    ) {
      const modifiedRequirement = findModifiedRequirementDocument(state.requirements, container);
      const modifiedContainer = (modifiedRequirement?.documentContainers ?? [])
        .concat(modifiedRequirement?.inheritedContainer)
        ?.find((item: DocumentContainer) => item.id === container.id);
      if (modifiedContainer) {
        const index = modifiedContainer?.places.findIndex((item: Business) => item.id === place.id);
        index >= 0 ? modifiedContainer.places.splice(index, 1) : modifiedContainer.places.push(place);
      }
    },
    setContainerTools(
      state: { requirements: Array<PageableRequirement<RequirementDocument>> },
      { container, tool }: { container: DocumentContainer; tool: Tool },
    ) {
      const modifiedRequirement = findModifiedRequirementDocument(state.requirements, container);
      const modifiedContainer = (modifiedRequirement?.documentContainers ?? [])
        .concat(modifiedRequirement?.inheritedContainer)
        ?.find((item: DocumentContainer) => item.id === container.id);
      if (modifiedContainer) {
        const index = modifiedContainer?.tools.findIndex((item: Tool) => item.id === tool.id);
        index >= 0 ? modifiedContainer.tools.splice(index, 1) : modifiedContainer.tools.push(tool);
      }
    },
    setDocumentContainersByYear(
      state: { requirements: Array<PageableRequirement<RequirementDocument>> },
      payload: DocumentContainer[],
    ) {
      payload.forEach((container: DocumentContainer) => {
        const modified = findModifiedRequirementDocument(state.requirements, container);
        if (modified.id) {
          const existingContainerIndex: number = modified?.documentContainers.findIndex(
            (item: DocumentContainer) => item.id === container.id,
          );
          if (existingContainerIndex >= 0) {
            modified?.documentContainers.splice(existingContainerIndex, 1);
          }
          modified.documentContainers.push(container);
        }
      });
    },
    addOperationalUnitDocuments(state: { operationalUnitDocuments: Pageable<Document> }, payload: Document[]) {
      state.operationalUnitDocuments.items = state.operationalUnitDocuments.items
        ? state.operationalUnitDocuments?.items.concat(payload)
        : payload;
    },
    removeOperationalUnitDocuments(state: { operationalUnitDocuments: Pageable<Document> }, payload: number) {
      const pageableDocuments = new Pageable<Document>(state.operationalUnitDocuments);
      pageableDocuments.removeById(payload);
    },
    addPlaceDocuments(state: { placeDocuments: Pageable<Document> }, payload: Document[]) {
      state.placeDocuments.items = state.placeDocuments.items ? state.placeDocuments?.items.concat(payload) : payload;
    },
    removePlaceDocuments(state: { placeDocuments: Pageable<Document> }, payload: number) {
      const pageableDocuments = new Pageable<Document>(state.placeDocuments);
      pageableDocuments.removeById(payload);
    },
    addPersonDocuments(state: { personDocuments: Pageable<Document> }, payload: Document[]) {
      state.personDocuments.items = state.personDocuments.items
        ? state.personDocuments?.items.concat(payload)
        : payload;
    },
    removePersonDocuments(state: { personDocuments: Pageable<Document> }, payload: number) {
      const pageableDocuments = new Pageable<Document>(state.personDocuments);
      pageableDocuments.removeById(payload);
    },
    addToolDocuments(state: { toolDocuments: Pageable<Document> }, payload: Document[]) {
      state.toolDocuments.items = state.toolDocuments.items ? state.toolDocuments?.items.concat(payload) : payload;
    },
    removeToolDocuments(state: { toolDocuments: Pageable<Document> }, payload: number) {
      const pageableDocuments = new Pageable<Document>(state.toolDocuments);
      pageableDocuments.removeById(payload);
    },
    setLayouts(state: { layouts: Document[] }, payload: Document[]) {
      state.layouts = payload;
    },
    addLayouts(state: { layouts: Document[] }, payload: Document[]) {
      state.layouts = state.layouts.concat(payload);
    },
    removeLayoutDocument(state: { layouts: Document[] }, payload: number) {
      const index = state.layouts.findIndex((item: Document) => item.id === payload);
      if (index >= 0) {
        state.layouts.splice(index, 1);
      }
    },
  },
  actions: {
    getRequirements(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      { pageOptions, category, years }: { pageOptions: PageOptions; category: string; years: number[] },
    ) {
      const searchText = rootGetters['filterStorage/getDocumentSearchText'];
      return axios
        .get('/document-store' + (category ? '/category' : ''), {
          params: { ...pageOptions, categoryName: category, text: searchText, years: years.join(',') },
        })
        .then(({ data }) => {
          if (data) {
            commit(category ? 'setCategoryRequirements' : 'setRequirements', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getDocuments({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, requirementId: number) {
      return axios
        .get(`/requirements/${requirementId}/documents`)
        .then(({ data }) => {
          if (data) {
            commit('setDocuments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadRequirementDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { requirementId, formData }: { requirementId: number; formData: FormData },
    ) {
      return axios
        .post(`/requirements/${requirementId}/documents`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addDocuments', data);
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadTaskDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { requirementId, taskId, formData }: { requirementId: number; taskId: number; formData: FormData },
    ) {
      return axios
        .post(`/requirements/${requirementId}/documents/${taskId}`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addDocuments', data);
            commit('requirementTaskStorage/addDocuments', { taskId, documents: data }, { root: true });
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deleteDocument(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { requirementId, documentId, taskId }: { requirementId: number; documentId: number; taskId: number },
    ) {
      return axios
        .delete(`/requirements/${requirementId}/documents/${documentId}`)
        .then(() => {
          commit('removeDocument', documentId);
          commit('requirementTaskStorage/removeDocument', { documentId, taskId }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setInherited(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { documentId, requirementId, taskId }: { documentId: number; requirementId: number; taskId: number },
    ) {
      return axios
        .get(`/requirements/${requirementId}/documents/inherit/${documentId}`)
        .then(({ data }) => {
          commit('modifyDocument', data);
          commit('requirementTaskStorage/modifyDocument', { document: data, taskId }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setExpiryDate(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        requirementId,
        documentId,
        request,
      }: { requirementId: number; documentId: number; request: DocumentExpiryRequest },
    ) {
      return axios
        .post(`/requirements/${requirementId}/documents/${documentId}/expiry`, request)
        .then(({ data }) => {
          commit('modifyDocument', data);
          if (data.taskId) {
            commit('requirementTaskStorage/setExpiryDate', data, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getOperationalUnitDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/operational-units/${id}/documents`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          commit('setOperationalUnitDocuments', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadOperationalUnitDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { id, formData }: { id: number; formData: FormData },
    ) {
      return axios
        .post(`/operational-units/${id}/documents`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addOperationalUnitDocuments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deleteOperationalUnitDocument({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/operational-units/${id}/documents`)
        .then(() => {
          commit('removeOperationalUnitDocuments', id);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPlaceDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/businesses/${id}/documents`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          commit('setPlaceDocuments', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadPlaceDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { id, formData }: { id: number; formData: FormData },
    ) {
      return axios
        .post(`/businesses/${id}/documents`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addPlaceDocuments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deletePlaceDocument({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/businesses/${id}/documents`)
        .then(() => {
          commit('removePlaceDocuments', id);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPersonDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/people/${id}/documents`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          commit('setPersonDocuments', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadPersonDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { id, formData }: { id: number; formData: FormData },
    ) {
      return axios
        .post(`/people/${id}/documents`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addPersonDocuments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deletePersonDocument({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/people/${id}/documents`)
        .then(() => {
          commit('removePersonDocuments', id);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getToolDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/tools/${id}/documents`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          commit('setToolDocuments', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadToolDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { id, formData }: { id: number; formData: FormData },
    ) {
      return axios
        .post(`/tools/${id}/documents`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addToolDocuments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deleteToolDocument({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/tools/${id}/documents`)
        .then(() => {
          commit('removeToolDocuments', id);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getLayoutDocumentsPerOperationalUnit(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      operationalUnitId: number,
    ) {
      return axios
        .get(`/operational-units/${operationalUnitId}/layouts`)
        .then(({ data }) => {
          commit('setLayouts', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    uploadLayoutDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { formData, operationalUnitId }: { formData: FormData; operationalUnitId: number },
    ) {
      return axios
        .post(`/operational-units/${operationalUnitId}/layouts`, formData)
        .then(({ data }) => {
          commit('addLayouts', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    removeLayoutDocument(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { layoutId, operationalUnitId }: { layoutId: number; operationalUnitId: number },
    ) {
      return axios
        .delete(`/operational-units/${operationalUnitId}/layouts/${layoutId}`)
        .then(() => {
          commit('removeLayoutDocument', layoutId);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getDocuments(state: { documents: Document[] }) {
      return state.documents;
    },
    getRequirements(state: { requirements: Array<PageableRequirement<RequirementDocument>> }) {
      return state.requirements;
    },
    getOperationalUnitDocuments(state: { operationalUnitDocuments: Pageable<Document> }) {
      return state.operationalUnitDocuments;
    },
    getPlaceDocuments(state: { placeDocuments: Pageable<Document> }) {
      return state.placeDocuments;
    },
    getPersonDocuments(state: { personDocuments: Pageable<Document> }) {
      return state.personDocuments;
    },
    getToolDocuments(state: { toolDocuments: Pageable<Document> }) {
      return state.toolDocuments;
    },
    getLayoutDocuments(state: { layouts: Document[] }) {
      return state.layouts;
    },
  },
};
